<template>
  <div style="width: 470px" ref="searchComponent" class="relative mt-3">
    <c-input-group>
      <c-input-left-element>
        <c-icon ml="2" name="search" color="gray.300" />
      </c-input-left-element>
      <c-input
        style="font-size: 13px"
        borderRadius="8px"
        @input.native="handleInput"
        @focus.native="trigger"
        @keyup.enter.native="goToSearchLanding"
        variant="filled"
        :width="['95%', '400px']"
        :value="search_param"
        placeholder="Search..."
      />
    </c-input-group>
    <div
        v-if="showSuggestions && search_param !== ''"
        class="suggestions pt-3 pb-4"
        style="width: 400px; border-radius: 8px"
    >
      <div
          style="max-height: 600px; overflow: hidden; overflow-y: scroll"
          v-if="search_param"
      >
        <c-text
            style="color: gray"
            v-if="filteredResults.length"
            fontSize="xs"
            fontWeight="bold"
        >Users found</c-text
        >
        <div
            v-for="user in filteredResults"
            @click.prevent="moveToUserProfile(user)"
            :key="user.id + 'AU'"
            class="p-2 hover:bg-gray-100 cursor-pointer"
            style="
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 50px;
          "
        >
          <c-flex align="baseline" style="height: 100%; margin-right: 33px; position: relative; overflow-x: hidden; text-overflow: ellipsis">
            <div style="display: flex; align-items: center; flex: 1"><c-text fontWeight="600" fontSize="sm">{{ user.firstname }}</c-text>
              <c-text class="ml-2" fontWeight="600" fontSize="sm">{{
                  user.lastname
                }}</c-text>
              <c-text mx="3" fontSize="xl" fontWeight="500"> • </c-text></div>
            <c-text ml-0 style="color: gray; height: 20px;  overflow: hidden; text-overflow: ellipsis" fontSize="xs" fontWeight="500">
              {{ user.companyName }}</c-text
            >
          </c-flex>

          <c-image
              style="position: absolute; right: 0"
              class="rounded-lg"
              :src="
              user.profilePhoto
                ? user.profilePhoto
                : require('@/assets/img/user.jpg')
            "
              :alt="user.firstname"
              w="30px"
              h="30px"
          />
        </div>
        <c-text
            style="color: gray"
            v-if="companyResults.length"
            fontSize="xs"
            fontWeight="bold"
        >Businesses found</c-text
        >
        <div
            v-for="res in companyResults"
            :key="res.id + 'XB'"
            @click="moveToCompanyProfile(res)"
            class="p-2 hover:bg-gray-100 cursor-pointer"
            style="
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 50px;
          "
        >
          <c-flex align="center" style="height: 100%; margin-right: 33px; overflow-x: hidden; text-overflow: ellipsis">
            <c-text fontWeight="600" fontSize="sm">{{ res.name }}</c-text>
          </c-flex>

          <c-image
              style="position: absolute; right: 0"
              class="rounded-lg"
              :src="res.logo ? res.logo : require('@/assets/img/company.png')"
              :alt="res.name"
              w="30px"
              h="30px"
          />
        </div>

        <c-text
            style="color: gray"
            v-if="channelResults.length"
            fontSize="xs"
            fontWeight="bold"
        >Channels found</c-text
        >
        <div
            v-for="res in channelResults"
            :key="res.id + 'CX'"
            @click="moveToChannel(res)"
            class="p-2 hover:bg-gray-100 cursor-pointer"
            style="
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 50px;
          "
        >
          <c-flex align="center" style="height: 100%; margin-right: 33px; overflow-x: hidden; text-overflow: ellipsis">
            <c-text fontWeight="600" fontSize="sm">{{ res.name }}</c-text>
          </c-flex>

          <span style="position: absolute; right: 0" class="first_letter">{{ getFirstLetter(res.name) }}</span>
        </div>
        <c-button
            v-if="
            channelResults.length || companyResults.length || results.length
          "
            style="
            display: flex;
            align-items: center;
            position: sticky;
            bottom: 0;
            width: 100%;
          "
            color="orange"
            variant-color="gray"
            mt="20px"
            @click="goToSearchLanding"
            variant="solid"
        >
          <c-text fontSize="sm" fontWeight="700">See all results</c-text>
        </c-button>
      </div>
    </div>
    <div
        v-if="(showRecentSearches && recentSearchList.length !== 0) && !search_param"
        class="previous-searches shadow-lg"
        style="z-index: 999"
    >
      <div v-if="recentSearchList.length !== 0" class="px-4 flex justify-between">
        <c-text fontSize="md" fontWeight="700">Recent searches</c-text>
        <c-button
            v-if="recentSearchList.length > 5"
            @click="showAllSearches"
            variant="link"
        >
          <c-text fontSize="md" fontWeight="700">View all</c-text>
        </c-button>
        <c-button v-else variant="link">
          <c-text @click.native="clearRecentSearches" fontSize="md" fontWeight="700"
          >Clear</c-text
          >
        </c-button>
      </div>
      <div style="width: 100%" class="flex mt-2 p-1">
        <div
            v-for="item in recentSearchList.slice(-5)"
            :key="item.id"
            @click="
            item.__typename === 'company'
              ? moveToCompanyProfile(item)
              : item.__typename === 'user_search'
              ? moveToUserProfile(item)
              : moveToChannel(item)
          "
            class="flex flex-col items-center p-2 hover:bg-gray-100 cursor-pointer"
            style="min-width: 70px; text-align: center"
        >
          <span
              v-if="item.__typename === 'chat_channel'"
              class="first_letter"
          >{{ getFirstLetter(item.name) }}</span
          >
          <c-image
              v-if="item.__typename === 'company'"
              class="rounded-lg"
              :src="item.logo ? item.logo : require('@/assets/img/company.png')"
              :alt="item.name"
              w="30px"
              h="30px"
          />

          <c-image
              v-if="item.__typename === 'user_search'"
              class="rounded-lg"
              :src="
              item.profilePhoto
                ? item.profilePhoto
                : require('@/assets/img/user.jpg')
            "
              :alt="item.name"
              w="30px"
              h="30px"
          />
          <div class="flex justify-center text-center flex items-center">
            <c-text fontSize="xs" class="font-semibold">
              {{
                item.__typename === 'company'
                    ? item.name
                    : item.__typename === 'user_search'
                        ? item.firstname + ' ' + item.lastname
                        : item.name
              }}
            </c-text>
          </div>
        </div>
      </div>
      <div
          v-for="(item, index) in recentTriedSearches"
          :key="index"
          @click.stop="goToSearchLandingWithParam(item)"
          class="flex pb-1 items-center px-6 hover:bg-gray-100 cursor-pointer"
          style="width: 100%"
      >
        <c-icon mr="4" name="clock"></c-icon>
        <p class="font-semibold">{{ item }}</p>
      </div>
      <!--      <c-text my="2" ml="5" fontSize="lg" fontWeight="600">People also search for</c-text>-->
      <!--      <div-->
      <!--          v-for="(item, index) in recent"-->
      <!--          :key="index"-->
      <!--          class="flex pb-1 items-center px-6 hover:bg-gray-100 cursor-pointer"-->
      <!--          style="width: 500px"-->
      <!--      >-->
      <!--        <c-icon mr="4" name="search"></c-icon>-->
      <!--        <p class="font-semibold">{{ item }}</p>-->
      <!--      </div>-->
    </div>
    <div v-if="showAll" class="all-searches">
      <div class="flex justify-between">
        <c-button ml="5" @click.stop="goBack" variant="link">
          <c-text fontSize="md" fontWeight="700">Back</c-text>
        </c-button>
        <c-button variant="link">
          <c-text @click="clearRecentSearches" fontSize="md" fontWeight="700"
          >Clear</c-text
          >
        </c-button>
      </div>
      <div
          v-for="itm in recentSearchList"
          :key="itm.id"
          style="background-color: #f4f8fe"
          class="flex justify-between pb-2 items-center bg-white px-6 hover:bg-gray-100 cursor-pointer"
          @click="
          itm.__typename === 'company'
            ? moveToCompanyProfile(itm)
            : moveToUserProfile(itm)
        "
      >
        <c-flex align="center">
          <c-icon mr="4" name="clock"></c-icon>
          <c-text></c-text>
          <c-text
              v-if="itm.__typename === 'company'"
              fontWeight="500"
              fontSize="lg"
          >{{ itm.name }}</c-text
          >
          <c-text v-else fontWeight="500" fontSize="lg"
          >{{ itm.firstname }} {{ itm.lastname }}</c-text
          >
        </c-flex>
        <c-image
            v-if="itm.__typename === 'company'"
            class="rounded-lg"
            :src="itm.logo ? itm.logo : require('@/assets/img/company.png')"
            :alt="itm.name"
            w="40px"
            h="40px"
        />
        <c-image
            v-else
            class="rounded-lg"
            :src="
            itm.profilePhoto
              ? itm.profilePhoto
              : require('@/assets/img/user.jpg')
          "
            :alt="itm.firstname"
            w="40px"
            h="40px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { searchChannel, searchCompany, searchUsers } from '@/services/insight';
import { mapGetters } from 'vuex';

export default {
  components: {},
  data() {
    return {
      showAll: false,
      showSuggestions: false,
      showRecentSearches: false,
      searchingStart: false,
      search_param: '',
      companyResults: [],
      channelResults: [],
      results: [],
      data_push: [],
    };
  },
  computed: {
    ...mapGetters({
      recentSearchList: 'search/getRecentSearches',
      recentTriedSearches: 'search/getRecentTriedSearches',
      user: 'auth/getUser',
      searchParam: 'explore/getSearchParams',
    }),
    filteredResults() {
      this.$store.dispatch('search/saveSearchResult', this.results);

      return this.results;
    },
    searchData: {
      get() {
        return this.search.data;
      },
      set(value) {
        this.search.data = value;
        // Trigger your search logic here if needed
      },
    },
  },
  methods: {
    goToSearchLandingWithParam() {
      this.$store.dispatch('search/saveRecentTriedSearches', this.search_param);
      this.$router.push({
        name: 'search-landing',
      });
    },
    getFirstLetter(name) {
      return name.charAt(0).toUpperCase();
    },
    moveToChannel(channel) {
      this.$store.dispatch('search/saveRecentSearches', channel);
      this.$router.push({
        name: 'ChannelMessaging',
        query: { channel: channel.name + channel.id },
      });
    },
    handleClickOutside(event) {
      const searchComponent = this.$refs.searchComponent;
      if (searchComponent && !searchComponent.contains(event.target)) {
        this.showSuggestions = false;
        this.showRecentSearches = false;
        this.showAll = false;
      }
    },
    clearRecentSearches() {
      this.$store.dispatch('search/clearRecentSearches');
    },
    trigger() {
      this.showAll = false;
      this.showRecentSearches = true;
      this.showSuggestions = true;
    },
    handleInput(event) {
      this.showRecentSearches = false;
      if (event.target) {
        console.log('Herexxxx', event.target.value);
        this.search_param = event.target.value;
        this.search(this.search_param);
      }
    },
    search: debounce(async function (searchQuery) {
      if (!searchQuery) {
        // await this.getUsers();
        return;
      }

      await this.$store.dispatch('explore/setSearchParam', searchQuery);

      // Replace with your actual search logic/API call
      await this.getUsersSearchResultsQuery();
      await this.getCompaniesSearchResultsQuery();
      await this.getChannelsSearchResultsQuery();
    }, 400),

    async getChannelsSearchResultsQuery() {
      if(this.search_param !== ""){
        const response = await searchChannel({
          search_param: '%' + this.search_param + '%',
          loggedInUserId: this.user.id,
          limit: 5,
          offset: 0,
        });
        const search_channels_result = response.data.chat_channel;

        console.log('Channels', search_channels_result);

        this.channelResults = search_channels_result;
      }
    },

    async getCompaniesSearchResultsQuery() {
      if(this.search_param !== ""){
        const response = await searchCompany({
          search_param: '%' + this.search_param + '%',
          limit: 7,
          offset: 0,
        });

        const search_companies_result = response.data.company;

        console.log('Returned here==>', search_companies_result);
        //  get only usrs who have firstname and lastname and companies array has data
        // limit to 5 users
        this.companyResults = search_companies_result;
        this.loading = false;
      }
    },

    async getUsersSearchResultsQuery() {
      console.log('Search starting ===xxx');
      const response = await searchUsers({
        search_param: '%' + this.search_param + '%',
        offset: 0,
        limit: 7,
      });

      const search_users_result = response.data.user_search;

      console.log('Returned here==>', search_users_result);
      //  get only usrs who have firstname and lastname and companies array has data
      // limit to 5 users
      this.results = search_users_result;
      this.loading = false;
    },
    goToSearchLanding() {
      this.$emit('hide-sidebar', true);
      this.$router.push({
        name: 'search-landing',
      });
      this.showSuggestions = false;
      this.$store.dispatch('explore/setSearchParam', this.search_param);
    },

    moveToUserProfile(user) {
      this.$store.dispatch('search/saveRecentSearches', user);
      this.$router.push({ name: 'Profile', params: { userId: user.id } });
      this.showSuggestions = false;
    },

    moveToCompanyProfile(company) {
      this.$store.dispatch('search/saveRecentSearches', company);
      this.$router.push({
        name: 'CompanyProfile',
        params: { companyId: company.id },
      });
      this.showSuggestions = false;
    },

    // moveToDetailPage(res){
    //   switch (res.type) {
    //     case 'company':
    //       this.moveToBusinessProfilePage(res.item);
    //       break;
    //     case 'user':
    //       this.moveToUserProfilePage(res.item);
    //       break;
    //     default:
    //       return;
    //   }
    // },
    // moveToUserProfilePage(user) {
    //   this.$emit('hide-sidebar', true);
    //   this.$router.push({ name: 'Profile', params: { user } });
    //   this.search = '';
    // },
    // moveToBusinessProfilePage(business) {
    //   this.$emit('hide-sidebar', true);
    //   this.$router.push({ name: 'CompanyProfile', params: { business } });
    //   this.search = '';
    // },
    showAllSearches() {
      this.showAll = true;
      this.showSuggestions = false;
    },
    goBack() {
      console.log('Going back =>');
      this.showSuggestions = true;
      this.showRecentSearches = true;
      this.showAll = false;
      this.search = '';
    },
  },

  created() {
    this.search_param = this.searchParam;
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.addEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.previous-searches,
.all-searches,
.view-all,
.suggestions {
  position: absolute;
  width: 400px;
  padding: 6px;
  background-color: #f4f8fe;
  border-radius: 8px;
  z-index: 10;
}

.first_letter {
  font-size: 17px;
  font-weight: 600;
  background-color: #e7592e;
  min-width: 32px;
  min-height: 27px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
